import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import { DamageReport } from '../../models/damageReports';
import { DamageMap } from '../../models/damageMaps';
import { addToast, ToastCategories, ToastTypes } from '../../models/toasts';
import updateLocalDataObject, { DataValue } from '../../utils/updateLocalDataObject';
import useMutationDeleteDamageReport from '../../services/hooks/damageMaps/useMutationDeleteDamageReport';
import useMutationEditDamageReport from '../../services/hooks/damageMaps/useMutationEditDamageReport';
import useMutationAddDamageReport from '../../services/hooks/damageReports/useMutationAddDamageReport';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import { DashboardState } from '../../models';
import { changeDrawerMode, changeDrawerVisibility, changeModalVisibility, DrawerMode } from '../../models/drawer';
import EditDamageReportDrawer from './EditDamageReportDrawer';
import ViewDamageReportDrawer from './ViewDamageReportDrawer';

interface DamageReportDrawerProps {
  damageReport?: DamageReport;
  damageMaps: DamageMap[];
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  span {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

const SaveButtonWrapper = styled.div`
  margin-right: 20px;
`;

const DamageReportDrawer: React.FC<DamageReportDrawerProps> = ({ damageReport, damageMaps }) => {
  const { drawer: { mode }, userSettings: { dateFormat } } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [damageReportData, setDamageReportData] = useState<DamageReport>(null);

  const handleOnSuccessDeleteReport = () => {
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const handleOnSuccessEditReport = () => {
    dispatch(changeDrawerMode({ payload: DrawerMode.VIEW }));
  };

  const handleAddDamageReportOnSuccess = (response: { data: DamageReport }) => {
    dispatch(changeDrawerMode({ payload: DrawerMode.VIEW }));
    const data = response?.data;
    setDamageReportData(data);
  };

  const deleteReport = useMutationDeleteDamageReport({ handleOnSuccessDeleteReport });
  const updateReport = useMutationEditDamageReport({ handleOnSuccessEditReport });
  const addReport = useMutationAddDamageReport({ handleAddDamageReportOnSuccess });

  const updateDamageReportData = (changes: { value: DataValue, key: string }[]) => {
    const newData = _.cloneDeep(damageReportData);
    const updatedData = updateLocalDataObject(changes, newData);
    setDamageReportData(updatedData);
  };

  const handleDamageReportDelete = () => {
    deleteReport.mutate(damageReportData.id);
  };

  const addNewToast = ({ message }) => {
    dispatch(
      addToast({
        payload: {
          title: formatMessage({ id: 'text.dataMissing' }),
          message,
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
  };

  const validateData = () => {
    if (!damageReportData?.type) {
      addNewToast({ message: formatMessage({ id: 'text.typeIsRequired' }) });
      return false;
    }
    if (!damageReportData?.details) {
      addNewToast({ message: formatMessage({ id: 'text.detailsAreRequired' }) });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validateData()) return;
    if (mode === DrawerMode.ADD) {
      addReport.mutate({ damageMapId: damageReportData.damage_map_id, damageReport: damageReportData });
    } else {
      const payload = {
        id: damageReportData.id,
        type: damageReportData.type,
        details: damageReportData.details,
        attachments_attributes: damageReportData.attachments,
      };
      updateReport.mutate(payload);
    }
  };

  const handleCancel = () => {
    if (damageReport !== damageReportData) {
      dispatch(changeModalVisibility({ payload: true }));
    } else {
      dispatch(changeDrawerMode({ payload: DrawerMode.VIEW }));
    }
  };

  useEffect(() => {
    if (damageReport) setDamageReportData(damageReport);
  }, [damageReport]);

  let title = 'Damage Report';
  if (mode === DrawerMode.EDIT) title = 'Editing Damage Report';

  const isReportedStatus = damageReportData?.status === 'reported';

  return (
    <DrawerWrapper>
      <Title data-testid="DamageReportDrawer--Title">
        <span>{title}</span>
        {isReportedStatus && (
          <AuthDropdownMenu
            options={{
              read: false,
              update: true,
              delete: true,
            }}
            menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
            resource={AircraftResource.DAMAGE_MAP}
            aircraftId={id}
            handleDelete={handleDamageReportDelete}
            editCallback={() =>
              dispatch(changeDrawerMode({ payload: DrawerMode.EDIT }))
            }
          />
        )}
      </Title>
      {mode === DrawerMode.VIEW ? (
        <ViewDamageReportDrawer
          damageReport={damageReportData}
          dateFormat={dateFormat}
          foundDamageMap={damageMaps.find((map) => map.id === damageReportData?.damage_map_id)}
        />
      ) : (
        <EditDamageReportDrawer
          damageReportData={damageReportData}
          updateDamageReportData={updateDamageReportData}
        />
      )}
      {mode !== DrawerMode.VIEW && (
        <ButtonWrapper data-testid="DamageReportDrawer--ButtonWrapper">
          <SaveButtonWrapper data-testid="DamageReportDrawer--SaveButtonWrapper">
            <Button height="30px" onClick={handleSave}>
              {formatMessage({ id: 'text.save' })}
            </Button>
          </SaveButtonWrapper>
          <Button height="30px" onClick={handleCancel}>
            {formatMessage({ id: 'text.cancel' })}
          </Button>
        </ButtonWrapper>
      )}
    </DrawerWrapper>
  )
};

DamageReportDrawer.defaultProps = {
  damageReport: null,
}

export default DamageReportDrawer;
